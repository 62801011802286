@import "~antd/dist/antd.css";
@import "~c3/c3.min.css";

.ab {
  width: 250px;
  display: inline-block;
  margin: 15px
}
.ant-layout{
  /* background-color: white; */
}
.App {
  /* text-align: center; */
}

.App-header h1 {
  color: whitesmoke;
}

.App-content {
  padding-top: 100px;
  padding-bottom: 100px;
}

.App-add-todo-input {
  max-width: 250px;
  margin: 5px;
  display: inline-block
}

.App-add-todo-button {}

.kk {
  background-color: black;

}

#tabletable {
  /* overflow-x: auto; */
}


.App-todos {
  /* background-color: white; */
  
  width: 100%;
  min-width: 900px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.App-todo {
  /* position: relative; */
}

.App-todo-complete {
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  position: absolute;
  right: 24px;
}